<template>
  <div class="divShow">
    <h3 class="head">{{ isText($t("L_DOC.CONTENT_0091")) }}</h3>
    <p class="p1">
      {{ isText($t("L_DOC.CONTENT_0092")) }}
    </p>
    <p class="h4">{{ isText($t("L_DOC.CONTENT_0093")) }}</p>
    <p class="p2">
      <span>{{ isText($t("L_DOC.CONTENT_0094")) }}</span>
      <span>
        <a class="link" href="#/product/house">{{ isText($t("L_DOC.CONTENT_0095")) }}</a>
      </span>
      <span>&nbsp;{{ isText($t("L_DOC.CONTENT_0096")) }}</span>
    </p>
    <p class="p2">{{ isText($t("L_DOC.CONTENT_0097")) }}</p>
    <p class="p2">{{ isText($t("L_DOC.CONTENT_0098")) }}</p>
    <p class="h4">{{ isText($t("L_DOC.CONTENT_0099")) }}</p>
    <p class="p2">{{ isText($t("L_DOC.CONTENT_0100")) }}</p>
    <ul>
      <li>{{ isText($t("L_DOC.CONTENT_0100_01")) }}</li>
    </ul>
    <p class="img"><el-image style="max-width: 88%;" :src="imgURL1" alt="image" lazy></el-image></p>
    <p class="p2">{{ isText($t("L_DOC.CONTENT_0101")) }}</p>
    <ul>
      <li>{{ isText($t("L_DOC.CONTENT_0101_01")) }}</li>
    </ul>
    <table>
      <tr>
        <td class="img">
          <el-image style="max-width: 88%;" :src="imgURL2" alt="image" lazy></el-image>
        </td>
        <td class="img">
          <el-image style="max-width: 38%;" :src="imgURL3" alt="image" lazy></el-image>
        </td>
      </tr>
    </table>
    <p class="p2">{{ isText($t("L_DOC.CONTENT_0102")) }}</p>
    <ul>
      <li>{{ isText($t("L_DOC.CONTENT_0102_01")) }}</li>
    </ul>
    <table>
      <tr>
        <td class="img">
          <el-image style="max-width: 88%;" :src="imgURL4" alt="image" lazy></el-image>
        </td>
        <td class="img">
          <el-image style="max-width: 38%;" :src="imgURL5" alt="image" lazy></el-image>
        </td>
      </tr>
    </table>
    <p class="h4">{{ isText($t("L_DOC.CONTENT_0103")) }}</p>
    <p class="p2">{{ isText($t("L_DOC.CONTENT_0104")) }}</p>
    <ul>
      <li>{{ isText($t("L_DOC.CONTENT_0104_01")) }}</li>
    </ul>
    <p class="img"><el-image style="max-width: 88%;" :src="imgURL6" alt="image" lazy></el-image></p>
    <p class="p2">{{ isText($t("L_DOC.CONTENT_0105")) }}</p>
    <ul>
      <li>{{ isText($t("L_DOC.CONTENT_0105_01")) }}</li>
      <li>{{ isText($t("L_DOC.CONTENT_0105_02")) }}</li>
      <li>{{ isText($t("L_DOC.CONTENT_0105_03")) }}</li>
      <li>{{ isText($t("L_DOC.CONTENT_0105_04")) }}</li>
    </ul>
    <p class="img"><el-image style="max-width: 88%;" :src="imgURL7" alt="image" lazy></el-image></p>
    <p class="p2">{{ isText($t("L_DOC.CONTENT_0106")) }}</p>
    <ul>
      <li>{{ isText($t("L_DOC.CONTENT_0106_01")) }}</li>
      <li>{{ isText($t("L_DOC.CONTENT_0106_02")) }}</li>
      <li>{{ isText($t("L_DOC.CONTENT_0106_03")) }}</li>
      <li>{{ isText($t("L_DOC.CONTENT_0106_04")) }}</li>
      <li>{{ isText($t("L_DOC.CONTENT_0106_05")) }}</li>
    </ul>
    <table>
      <tr>
        <td class="img">
          <el-image style="max-width: 38%;" :src="imgURL8" alt="image" lazy></el-image>
        </td>
        <td class="img">
          <el-image style="max-width: 88%;" :src="imgURL9" alt="image" lazy></el-image>
        </td>
      </tr>
      <tr>
        <td class="img">
          <el-image style="max-width: 38%;" :src="imgURL10" alt="image" lazy></el-image>
        </td>
        <td class="img">
          <el-image style="max-width: 38%;" :src="imgURL11" alt="image" lazy></el-image>
        </td>
      </tr>
      <tr>
        <td class="img">
          <el-image style="max-width: 38%;" :src="imgURL12" alt="image" lazy></el-image>
        </td>
        <td class="img">
          <el-image style="max-width: 38%;" :src="imgURL14" alt="image" lazy></el-image>
        </td>
      </tr>
    </table>
    <p class="p2">{{ isText($t("L_DOC.CONTENT_0107")) }}</p>
    <ul>
      <li>{{ isText($t("L_DOC.CONTENT_0107_01")) }}</li>
      <li>{{ isText($t("L_DOC.CONTENT_0107_02")) }}</li>
      <li>{{ isText($t("L_DOC.CONTENT_0107_03")) }}</li>
      <li>{{ isText($t("L_DOC.CONTENT_0107_04")) }}</li>
      <li>{{ isText($t("L_DOC.CONTENT_0107_05")) }}</li>
    </ul>
    <table>
      <tr>
        <td class="img">
          <el-image style="max-width: 68%;" :src="imgURL15" alt="image" lazy></el-image>
        </td>
        <td class="img">
          <el-image style="max-width: 88%;" :src="imgURL16" alt="image" lazy></el-image>
        </td>
      </tr>
      <tr>
        <td class="img">
          <el-image style="max-width: 88%;" :src="imgURL17" alt="image" lazy></el-image>
        </td>
        <td class="img">
          <el-image style="max-width: 48%;" :src="imgURL18" alt="image" lazy></el-image>
        </td>
      </tr>
      <tr>
        <td class="img">
          <el-image style="max-width: 38%;" :src="imgURL19" alt="image" lazy></el-image>
        </td>
        <td class="img">
          <el-image style="max-width: 38%;" :src="imgURL20" alt="image" lazy></el-image>
        </td>
      </tr>
    </table>
    <p class="p2">{{ isText($t("L_DOC.CONTENT_0108")) }}</p>
    <ul>
      <li>{{ isText($t("L_DOC.CONTENT_0108_01")) }}</li>
      <li>{{ isText($t("L_DOC.CONTENT_0108_02")) }}</li>
      <li>{{ isText($t("L_DOC.CONTENT_0108_03")) }}</li>
    </ul>
    <table>
      <tr>
        <td class="img">
          <el-image style="max-width: 48%;" :src="imgURL21" alt="image" lazy></el-image>
        </td>
        <td class="img">
          <el-image style="max-width: 88%;" :src="imgURL22" alt="image" lazy></el-image>
        </td>
      </tr>
    </table>
    <p class="p2">{{ isText($t("L_DOC.CONTENT_0109")) }}</p>
    <ul>
      <li>{{ isText($t("L_DOC.CONTENT_0109_01")) }}</li>
      <li>{{ isText($t("L_DOC.CONTENT_0109_02")) }}</li>
      <li>{{ isText($t("L_DOC.CONTENT_0109_03")) }}</li>
      <li>{{ isText($t("L_DOC.CONTENT_0109_04")) }}</li>
      <li>{{ isText($t("L_DOC.CONTENT_0109_05")) }}</li>
    </ul>
    <table>
      <tr>
        <td class="img">
          <el-image style="max-width: 88%;" :src="imgURL23" alt="image" lazy></el-image>
        </td>
        <td class="img">
          <el-image style="max-width: 88%;" :src="imgURL24" alt="image" lazy></el-image>
        </td>
      </tr>
      <tr>
        <td class="img">
          <el-image style="max-width: 78%;" :src="imgURL25" alt="image" lazy></el-image>
        </td>
        <td class="img">
          <el-image style="max-width: 58%;" :src="imgURL26" alt="image" lazy></el-image>
        </td>
      </tr>
    </table>
    <p class="h4">{{ isText($t("L_DOC.CONTENT_0110")) }}</p>
    <p class="p2">{{ isText($t("L_DOC.CONTENT_0111")) }}</p>
    <p class="p2">{{ isText($t("L_DOC.CONTENT_0112")) }}</p>
    <p class="p2">{{ isText($t("L_DOC.CONTENT_0113")) }}</p>
    <p class="p2">{{ isText($t("L_DOC.CONTENT_0114")) }}</p>
    <p class="p2">{{ isText($t("L_DOC.CONTENT_0115")) }}</p>
    <p class="p2 mb70">{{ isText($t("L_DOC.CONTENT_0116")) }}</p>
  </div>
</template>

<script>
let image1 = require("@/assets/image/pc/userguide/zh_image/building/building_01.png");
let image2 = require("@/assets/image/pc/userguide/zh_image/building/building_02.png");
let image3 = require("@/assets/image/pc/userguide/zh_image/building/building_03.png");
let image4 = require("@/assets/image/pc/userguide/zh_image/building/building_04.png");
let image5 = require("@/assets/image/pc/userguide/zh_image/building/building_05.png");
let image6 = require("@/assets/image/pc/userguide/zh_image/building/building_06.png");
let image7 = require("@/assets/image/pc/userguide/zh_image/building/building_07.png");
let image8 = require("@/assets/image/pc/userguide/zh_image/building/building_08.png");
let image9 = require("@/assets/image/pc/userguide/zh_image/building/building_09.png");
let image10 = require("@/assets/image/pc/userguide/zh_image/building/building_10.png");
let image11 = require("@/assets/image/pc/userguide/zh_image/building/building_11.png");
let image12 = require("@/assets/image/pc/userguide/zh_image/building/building_12.png");
let image14 = require("@/assets/image/pc/userguide/zh_image/building/building_14.png");
let image15 = require("@/assets/image/pc/userguide/zh_image/building/building_15.png");
let image16 = require("@/assets/image/pc/userguide/zh_image/building/building_16.png");
let image17 = require("@/assets/image/pc/userguide/zh_image/building/building_17.png");
let image18 = require("@/assets/image/pc/userguide/zh_image/building/building_18.png");
let image19 = require("@/assets/image/pc/userguide/zh_image/building/building_19.png");
let image20 = require("@/assets/image/pc/userguide/zh_image/building/building_20.png");
let image21 = require("@/assets/image/pc/userguide/zh_image/building/building_21.png");
let image22 = require("@/assets/image/pc/userguide/zh_image/building/building_22.png");
let image23 = require("@/assets/image/pc/userguide/zh_image/building/building_23.png");
let image24 = require("@/assets/image/pc/userguide/zh_image/building/building_24.png");
let image25 = require("@/assets/image/pc/userguide/zh_image/building/building_25.png");
let image26 = require("@/assets/image/pc/userguide/zh_image/building/building_26.png");

export default {
  name: "building",
  data() {
    return {
      imgURL1: image1,
      imgURL2: image2,
      imgURL3: image3,
      imgURL4: image4,
      imgURL5: image5,
      imgURL6: image6,
      imgURL7: image7,
      imgURL8: image8,
      imgURL9: image9,
      imgURL10: image10,
      imgURL11: image11,
      imgURL12: image12,
      imgURL14: image14,
      imgURL15: image15,
      imgURL16: image16,
      imgURL17: image17,
      imgURL18: image18,
      imgURL19: image19,
      imgURL20: image20,
      imgURL21: image21,
      imgURL22: image22,
      imgURL23: image23,
      imgURL24: image24,
      imgURL25: image25,
      imgURL26: image26,
    };
  },
  methods: {
    isText(val) {
      if (val) {
        if (val.includes('L_DOC')) {
          return "";
        } else {
          return val;
        }
      } else {
        return "";
      }
    }
  }
};
</script>

<style scoped></style>
