<template>
  <div class="divShow">
    <h3 class="head">{{ isText($t("L_DOC.CONTENT_0060")) }}</h3>
    <p class="p1">
      {{ isText($t("L_DOC.CONTENT_0061")) }}
    </p>
    <p class="h4">{{ isText($t("L_DOC.CONTENT_0031")) }}</p>
    <p class="p2">
      <span>{{ isText($t("L_DOC.CONTENT_0032")) }}</span>
      <span>
        <a class="link" href="#/product/house">{{ isText($t("L_DOC.CONTENT_0062")) }}</a>
      </span>
      <span>&nbsp;{{ isText($t("L_DOC.CONTENT_0034")) }}</span>
    </p>
    <p class="p2">{{ isText($t("L_DOC.CONTENT_0035")) }}</p>
    <p class="p2">{{ isText($t("L_DOC.CONTENT_0063")) }}</p>
    <p class="h4">{{ isText($t("L_DOC.CONTENT_0064")) }}</p>
    <ul>
      <li>{{ isText($t("L_DOC.CONTENT_0065_01")) }}</li>
      <li>{{ isText($t("L_DOC.CONTENT_0065_02")) }}</li>
    </ul>
    <p class="img">
      <el-image style="max-width: 88%; text-align: center;" :src="imgURL1" alt="image" lazy></el-image>
    </p>
    <p class="h4">{{ isText($t("L_DOC.CONTENT_0066")) }}</p>
    <ul>
      <li>
        {{ isText($t("L_DOC.CONTENT_0067")) }}
        <table>
          <tr>
            <td>
              <el-image style="max-width: 88%; text-align: center;" :src="imgURL2" alt="image" lazy></el-image>
            </td>
            <td>
              <el-image style="max-width: 70%; text-align: center;" :src="imgURL3" alt="image" lazy></el-image>
            </td>
          </tr>
        </table>
      </li>
      <li>{{ isText($t("L_DOC.CONTENT_0068")) }}</li>
      <ul>
        <li class="no-list">{{ isText($t("L_DOC.CONTENT_0068_01")) }}</li>
        <li class="no-list">{{ isText($t("L_DOC.CONTENT_0068_02")) }}</li>
        <li class="no-list">{{ isText($t("L_DOC.CONTENT_0068_03")) }}</li>
      </ul>
      <table>
        <tr>
          <td>
            <el-image style="max-width: 98%; text-align: center;" :src="imgURL4" alt="image" lazy></el-image>
          </td>
          <td>
            <el-image style="max-width: 50%; text-align: center;" :src="imgURL5" alt="image" lazy></el-image>
          </td>
        </tr>
      </table>
      <li>{{ isText($t("L_DOC.CONTENT_0069")) }}</li>
      <table>
        <tr>
          <td>
            <el-image style="max-width: 88%; text-align: center;" :src="imgURL6" alt="image" lazy></el-image>
          </td>
          <td>
            <el-image style="max-width: 88%; text-align: center;" :src="imgURL7" alt="image" lazy></el-image>
          </td>
        </tr>
      </table>
      <li>{{ isText($t("L_DOC.CONTENT_0070")) }}</li>
      <ul>
        <li class="no-list">{{ isText($t("L_DOC.CONTENT_0070_01")) }}</li>
        <li class="no-list">{{ isText($t("L_DOC.CONTENT_0070_02")) }}</li>
      </ul>
      <p class="img">
        <el-image style="max-width: 88%; text-align: center;" :src="imgURL8" alt="image" lazy></el-image>
      </p>
    </ul>
    <p class="h4">{{ isText($t("L_DOC.CONTENT_0071")) }}</p>
    <ul>
      <li>{{ isText($t("L_DOC.CONTENT_0072")) }}</li>
      <table cellspacing="10px">
        <tr>
          <td>
            <el-image style="max-width: 108%; text-align: center;" :src="imgURL9" alt="image" lazy></el-image>
          </td>
          <td>
            <el-image style="max-width: 58%; text-align: center;" :src="imgURL10" alt="image" lazy></el-image>
          </td>
        </tr>
      </table>
      <li>{{ isText($t("L_DOC.CONTENT_0073")) }}</li>
      <p class="img">
        <el-image style="max-width: 88%; text-align: center;" :src="imgURL11" alt="image" lazy></el-image>
      </p>
      <li>{{ isText($t("L_DOC.CONTENT_0074")) }}</li>
      <table>
        <tr>
          <td>
            <el-image style="max-width: 88%; text-align: center;" :src="imgURL12" alt="image" lazy></el-image>
          </td>
          <td>
            <el-image style="max-width: 88%; text-align: center;" :src="imgURL13" alt="image" lazy></el-image>
          </td>
        </tr>
      </table>
    </ul>
    <p class="h4">{{ isText($t("L_DOC.CONTENT_0075")) }}</p>
    <ul>
      <li>{{ isText($t("L_DOC.CONTENT_0076")) }}</li>
      <li>{{ isText($t("L_DOC.CONTENT_0077")) }}</li>
      <li>{{ isText($t("L_DOC.CONTENT_0078")) }}</li>
    </ul>
    <p class="h4">{{ isText($t("L_DOC.CONTENT_0079")) }}</p>
    <ul>
      <li>{{ isText($t("L_DOC.CONTENT_0080")) }}</li>
    </ul>
    <p class="h4">{{ isText($t("L_DOC.CONTENT_0081")) }}</p>
    <ul>
      <li>{{ isText($t("L_DOC.CONTENT_0082")) }}</li>
      <li>{{ isText($t("L_DOC.CONTENT_0083")) }}</li>
    </ul>
    <p class="h4">{{ isText($t("L_DOC.CONTENT_0084")) }}</p>
    <p class="p2">{{ isText($t("L_DOC.CONTENT_0085")) }}</p>
    <p class="p2">{{ isText($t("L_DOC.CONTENT_0086")) }}</p>
    <p class="p2">{{ isText($t("L_DOC.CONTENT_0087")) }}</p>
    <p class="p2">{{ isText($t("L_DOC.CONTENT_0088")) }}</p>
    <p class="p2">{{ isText($t("L_DOC.CONTENT_0089")) }}</p>
    <p class="p2 mb70">{{ isText($t("L_DOC.CONTENT_0090")) }}</p>
  </div>
</template>

<script>
let image1 = require("@/assets/image/pc/userguide/zh_image/disaster/search_01.png");
let image2 = require("@/assets/image/pc/userguide/zh_image/disaster/insar_01.png");
let image3 = require("@/assets/image/pc/userguide/zh_image/disaster/insar_02.png");
let image4 = require("@/assets/image/pc/userguide/zh_image/disaster/insar_03.png");
let image5 = require("@/assets/image/pc/userguide/zh_image/disaster/insar_04.png");
let image6 = require("@/assets/image/pc/userguide/zh_image/disaster/insar_05.png");
let image7 = require("@/assets/image/pc/userguide/zh_image/disaster/insar_06.png");
let image8 = require("@/assets/image/pc/userguide/zh_image/disaster/insar_07.png");
let image9 = require("@/assets/image/pc/userguide/zh_image/disaster/star_01.png");
let image10 = require("@/assets/image/pc/userguide/zh_image/disaster/star_02.png");
let image11 = require("@/assets/image/pc/userguide/zh_image/disaster/climate.png");
let image12 = require("@/assets/image/pc/userguide/zh_image/disaster/choosecity.png");
let image13 = require("@/assets/image/pc/userguide/zh_image/disaster/choosecity_2.png");
export default {
  name: "disaster",
  data() {
    return {
      imgURL1: image1,
      imgURL2: image2,
      imgURL3: image3,
      imgURL4: image4,
      imgURL5: image5,
      imgURL6: image6,
      imgURL7: image7,
      imgURL8: image8,
      imgURL9: image9,
      imgURL10: image10,
      imgURL11: image11,
      imgURL12: image12,
      imgURL13: image13,
    };
  },
  methods: {
    isText(val) {
      if (val) {
        if (val.includes('L_DOC')) {
          return "";
        } else {
          return val;
        }
      } else {
        return "";
      }
    }
  }
};
</script>

<style scoped></style>
