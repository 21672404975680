<template>
  <div class="divShow">
    <h3 class="head">{{ $t("L_DOC.CONTENT_0001") }}</h3>
    <p class="p1">
      {{ $t("L_DOC.CONTENT_0002") }}
    </p>
    <p class="h4">{{ $t("L_DOC.CONTENT_0003") }}</p>
    <p class="p2">
      <span>{{ $t("L_DOC.CONTENT_0004") }}</span>
      <span>
        <a class="link" href="#/product/insar">{{ $t("L_DOC.CONTENT_0005") }}</a>
      </span>
      <span>&nbsp;{{ $t("L_DOC.CONTENT_0006") }}</span>
    </p>
    <p class="p2">{{ $t("L_DOC.CONTENT_0007") }}</p>
    <p class="p2">{{ $t("L_DOC.CONTENT_0008") }}</p>
    <p class="img"><el-image style="max-width: 88%" :src="imgURL1" alt="image" lazy></el-image></p>

    <p class="h4">{{ $t("L_DOC.CONTENT_0009") }}</p>
    <p class="p2">{{ $t("L_DOC.CONTENT_0010") }}</p>
    <ul>
      <li>{{ $t("L_DOC.CONTENT_0010_01") }}</li>
      <li>{{ $t("L_DOC.CONTENT_0010_02") }}</li>
      <li>{{ $t("L_DOC.CONTENT_0010_03") }}</li>
    </ul>
    <p class="p2">{{ $t("L_DOC.CONTENT_0011") }}</p>
    <p class="p2">{{ $t("L_DOC.CONTENT_0012") }}</p>
    <ul>
      <li>{{ $t("L_DOC.CONTENT_0012_01") }}</li>
    </ul>
    <p class="p2">{{ $t("L_DOC.CONTENT_0013") }}</p>
    <ul>
      <li>{{ $t("L_DOC.CONTENT_0013_01") }}</li>
    </ul>
    <p class="p2">{{ $t("L_DOC.CONTENT_0014") }}</p>
    <p class="p2">{{ $t("L_DOC.CONTENT_0015") }}</p>
    <p class="p2">{{ $t("L_DOC.CONTENT_0055") }}</p>
    <ul>
      <li>{{ $t("L_DOC.CONTENT_0055_01") }}</li>
      <li>{{ $t("L_DOC.CONTENT_0055_02") }}</li>
      <li>{{ $t("L_DOC.CONTENT_0055_03") }}</li>
      <li>{{ $t("L_DOC.CONTENT_0055_04") }}</li>
      <li>{{ $t("L_DOC.CONTENT_0055_05") }}</li>
      <li>{{ $t("L_DOC.CONTENT_0055_06") }}</li>
      <li>{{ $t("L_DOC.CONTENT_0055_07") }}</li>
    </ul>
    <p class="h4">{{ $t("L_DOC.CONTENT_0016") }}</p>
    <p class="p2">{{ $t("L_DOC.CONTENT_0017") }}</p>
    <ul>
      <li>{{ $t("L_DOC.CONTENT_0017_01") }}</li>
      <li>{{ $t("L_DOC.CONTENT_0017_02") }}</li>
      <ul>
        <li class="no_list">{{ $t("L_DOC.CONTENT_0017_02_01") }}</li>
        <li class="no_list">{{ $t("L_DOC.CONTENT_0017_02_02") }}</li>
        <li class="no_list">{{ $t("L_DOC.CONTENT_0017_02_03") }}</li>
      </ul>
      <li>{{ $t("L_DOC.CONTENT_0017_03") }}</li>
      <ul>
        <li class="no_list">{{ $t("L_DOC.CONTENT_0017_03_01") }}</li>
        <li class="no_list">{{ $t("L_DOC.CONTENT_0017_03_02") }}</li>
      </ul>
    </ul>
    <p class="img"><el-image style="max-width: 90%" :src="imgURL2" alt="image" lazy></el-image></p>
    <p class="p2">{{ $t("L_DOC.CONTENT_0018") }}</p>
    <ul>
      <li>{{ $t("L_DOC.CONTENT_0018_01") }}</li>
      <li>{{ $t("L_DOC.CONTENT_0018_02") }}</li>
      <li>{{ $t("L_DOC.CONTENT_0018_03") }}</li>
    </ul>
    <p class="img"><el-image :src="imgURL3" alt="image" lazy></el-image></p>
    <p class="p2">{{ $t("L_DOC.CONTENT_0019") }}</p>
    <ul>
      <li>{{ $t("L_DOC.CONTENT_0019_01") }}</li>
      <li>{{ $t("L_DOC.CONTENT_0019_02") }}</li>
      <li>{{ $t("L_DOC.CONTENT_0019_03") }}</li>
      <li>{{ $t("L_DOC.CONTENT_0019_04") }}</li>
      <li>{{ $t("L_DOC.CONTENT_0019_05") }}</li>
    </ul>
    <p class="p2">{{ $t("L_DOC.CONTENT_0020") }}</p>
    <p class="h4">{{ $t("L_DOC.CONTENT_0021") }}</p>
    <p class="p2">{{ $t("L_DOC.CONTENT_0022") }}</p>
    <p class="p2">{{ $t("L_DOC.CONTENT_0023") }}</p>
    <p class="p2">{{ $t("L_DOC.CONTENT_0024") }}</p>
    <p class="p2">{{ $t("L_DOC.CONTENT_0025") }}</p>
    <p class="p2">{{ $t("L_DOC.CONTENT_0057") }}</p>
    <p class="p2">{{ $t("L_DOC.CONTENT_0026") }}</p>
    <p class="p2">{{ $t("L_DOC.CONTENT_0027") }}</p>
    <p class="p2 mb80">{{ $t("L_DOC.CONTENT_0028") }}</p>
  </div>
</template>

<script>
let image1_1 = require("@/assets/image/pc/userguide/zh_image/insar/insar1.png");
let image1_2 = require("@/assets/image/pc/userguide/en_image/insar/insar1.png");
let image2_1 = require("@/assets/image/pc/userguide/zh_image/insar/insar2.png");
let image2_2 = require("@/assets/image/pc/userguide/en_image/insar/insar2.png");
let image3_1 = require("@/assets/image/pc/userguide/zh_image/insar/insar3.png");
let image3_2 = require("@/assets/image/pc/userguide/en_image/insar/insar3.png");
export default {
  name: "insar",
  data() {
    return {
      imgURL1: null,
      imgURL2: null,
      imgURL3: null,
    };
  },
  computed: {
    currentLang() {
      return this.$i18n.locale;
    },
  },
  mounted() {
    this.renderImages(this.currentLang);
  },
  watch: {
    currentLang(val) {
      this.renderImages(val);
    }
  },
  methods: {
    renderImages(language) {
      if (language === "zh") {
        this.imgURL1 = image1_1;
        this.imgURL2 = image2_1;
        this.imgURL3 = image3_1;
      } else if (language === "en") {
        this.imgURL1 = image1_2;
        this.imgURL2 = image2_2;
        this.imgURL3 = image3_2;
      }
    }
  }
};
</script>

<style scoped></style>
