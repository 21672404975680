<template>
  <div class="divShow">
    <h3 class="head">{{ $t("L_DOC.CONTENT_0029") }}</h3>
    <p class="p1">
      {{ $t("L_DOC.CONTENT_0030") }}
    </p>
    <p class="h4">{{ $t("L_DOC.CONTENT_0031") }}</p>
    <p class="p2">
      <span>{{ $t("L_DOC.CONTENT_0032") }}</span>
      <span>
        <a class="link" href="#/product/house">{{ $t("L_DOC.CONTENT_0033") }}</a>
      </span>
      <span>&nbsp;{{ $t("L_DOC.CONTENT_0034") }}</span>
    </p>
    <p class="p2">{{ $t("L_DOC.CONTENT_0035") }}</p>
    <p class="p2">{{ $t("L_DOC.CONTENT_0036") }}</p>
    <p class="img"><el-image style="max-width: 88%" :src="imgURL1" alt="image" lazy></el-image></p>
    <p class="h4">{{ $t("L_DOC.CONTENT_0037") }}</p>
    <p class="p2">{{ $t("L_DOC.CONTENT_0038") }}</p>
    <ul>
      <li>{{ $t("L_DOC.CONTENT_0038_01") }}</li>
      <li>{{ $t("L_DOC.CONTENT_0038_02") }}</li>
      <li>{{ $t("L_DOC.CONTENT_0038_03") }}</li>
      <li>{{ $t("L_DOC.CONTENT_0038_04") }}</li>
      <li>{{ $t("L_DOC.CONTENT_0038_05") }}</li>
    </ul>
    <p class="p2">{{ $t("L_DOC.CONTENT_0039") }}</p>
    <ul>
      <li>{{ $t("L_DOC.CONTENT_0039_01") }}</li>
      <li>{{ $t("L_DOC.CONTENT_0039_02") }}</li>
    </ul>
    <p class="img"><el-image :src="imgURL2" alt="image" lazy></el-image></p>
    <p class="h4">{{ $t("L_DOC.CONTENT_0041") }}</p>
    <p class="p2">{{ $t("L_DOC.CONTENT_0042") }}</p>
    <ul>
      <li>{{ $t("L_DOC.CONTENT_0042_01") }}</li>
      <li>{{ $t("L_DOC.CONTENT_0042_02") }}</li>
      <li>{{ $t("L_DOC.CONTENT_0042_03") }}</li>
      <li>{{ $t("L_DOC.CONTENT_0042_04") }}</li>
      <li>{{ $t("L_DOC.CONTENT_0042_05") }}</li>
      <li>{{ $t("L_DOC.CONTENT_0042_06") }}</li>
      <li>{{ $t("L_DOC.CONTENT_0042_07") }}</li>
    </ul>
    <p class="p2">{{ $t("L_DOC.CONTENT_0043") }}</p>
    <ul>
      <li>{{ $t("L_DOC.CONTENT_0043_01") }}</li>
      <li>{{ $t("L_DOC.CONTENT_0043_02") }}</li>
      <li>{{ $t("L_DOC.CONTENT_0043_03") }}</li>
      <li>{{ $t("L_DOC.CONTENT_0043_04") }}</li>
    </ul>
    <p class="p2">{{ $t("L_DOC.CONTENT_0044") }}</p>
    <ul>
      <li>{{ $t("L_DOC.CONTENT_0045") }}</li>
    </ul>
    <p class="p2">{{ $t("L_DOC.CONTENT_0046") }}</p>
    <p class="img">
      <el-image style="max-width: 88%" :src="imgURL3" alt="image" lazy></el-image>
    </p>
    <p class="h4">{{ $t("L_DOC.CONTENT_0021") }}</p>
    <p class="p2">{{ $t("L_DOC.CONTENT_0048") }}</p>
    <p class="p2">{{ $t("L_DOC.CONTENT_0049") }}</p>
    <p class="p2">{{ $t("L_DOC.CONTENT_0050") }}</p>
    <p class="p2">{{ $t("L_DOC.CONTENT_0051") }}</p>
    <p class="p2">{{ $t("L_DOC.CONTENT_0052") }}</p>
    <p class="p2">{{ $t("L_DOC.CONTENT_0053") }}</p>
    <p class="p2 mb70">{{ $t("L_DOC.CONTENT_0054") }}</p>
  </div>
</template>

<script>
let image1_1 = require("@/assets/image/pc/userguide/zh_image/house/house1.png");
let image1_2 = require("@/assets/image/pc/userguide/en_image/house/house1.png");
let image2_1 = require("@/assets/image/pc/userguide/zh_image/house/house2.png");
let image2_2 = require("@/assets/image/pc/userguide/en_image/house/house2.png");
let image3_1 = require("@/assets/image/pc/userguide/zh_image/house/house3.png");
let image3_2 = require("@/assets/image/pc/userguide/en_image/house/house3.png");
export default {
  name: "house",
  data() {
    return {
      imgURL1: null,
      imgURL2: null,
      imgURL3: null,
    };
  },
  computed: {
    currentLang() {
      return this.$i18n.locale;
    },
  },
  mounted() {
    this.renderImages(this.currentLang);
  },
  watch: {
    currentLang(val) {
      this.renderImages(val);
    }
  },
  methods: {
    renderImages(language) {
      if (language === "zh") {
        this.imgURL1 = image1_1;
        this.imgURL2 = image2_1;
        this.imgURL3 = image3_1;
      } else if (language === "en") {
        this.imgURL1 = image1_2;
        this.imgURL2 = image2_2;
        this.imgURL3 = image3_2;
      }
    }
  }
};
</script>

<style scoped></style>
