<template>
  <div class="userguide-wrapper">
    <div class="nav">
      <div class="nav_title">
        <div class="section-img">
          <h3 class="title">{{ $t("L_DOC.TITLE") }}</h3>
        </div>
      </div>
      <ul class="nav_header">
        <li
          v-for="(item, index) in navList"
          :key="index"
          :class="selected === item.id ? 'active' : ''"
          @click="handleClickMenu(item)"
        >
          {{ item.name }}
        </li>
      </ul>
    </div>
    <div class="center-pageCenter">
      <insar ref="insar" v-if="selected === 1"></insar>
      <house ref="house" v-else-if="selected === 2"></house>
      <disaster ref="disaster" v-else-if="selected === 3"></disaster>
      <building ref="building" v-else-if="selected === 4"></building>
    </div>
  </div>
</template>

<script>
import insar from "@/components/pc/userguide/insar.vue";
import house from "@/components/pc/userguide/house.vue";
import disaster from "@/components/pc/userguide/disaster.vue";
import building from "@/components/pc/userguide/building.vue";
export default {
  name: "userguide",
  components: {
    insar,
    house,
    disaster,
    building,
  },
  data() {
    return {
      navList: [
        {
          id: 1,
          name: this.$t("L_DOC.NAV_0001"),
        },
        {
          id: 2,
          name: this.$t("L_DOC.NAV_0002"),
        },
        {
          id: 3,
          name: this.$t("L_DOC.NAV_0003"),
        },
        {
          id: 4,
          name: this.$t("L_DOC.NAV_0004"),
        },
      ],
      selected: null,
    };
  },
  mounted() {
    this.handleClickMenu(this.navList[0]);
  },
  methods: {
    handleClickMenu(row) {
      this.selected = row.id;
    },
  },
};
</script>

<style lang="scss">
@import "../../../assets/styles/pc/userguide/index.scss";
</style>
